import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Layout = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen(!open);
  };

  return (
    <div className="">
      <div
        className={`z-10 fixed top-0 left-0 bottom-0 right-0 lg:hidden bg-slate-500 bg-opacity-60 ${
          open ? "visible" : "hidden"
        }`}
        onClick={toggleMenu}
      >
        <Sidebar toggleMenu={toggleMenu} />
      </div>

      <Navbar toggleMenu={toggleMenu} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
