import PriceCard from "../../../shared/components/PriceCard";

export const plans = [
  {
    type: "Standard",
    price: "2,000",
    description:
      "Get started quickly as the admin of your school. Manage all activities",
    features: [
      "Student registration",
      "Add teachers",
      "Manage scorecards",
      "Report card computation",
      "3 Junior classes (Jss1, Jss2, Jss3)",
      "3 Senior classes (Sss1, Sss2, Sss3)",
      "Manage class attendance",
      "Manage class subjects",
      "Email & In app notifications",
      "Manage school events",
    ],
  },
  {
    type: "Premium",
    price: "5,000",
    description:
      "Add and Collaborate with your teachers to run your day-to-day activites",
    features: [
      "All standard plan",
      "Teacher's workspace",
      "Create junior subclasses",
      "Create senior subclasses",
      "Download report card",
      "Download class attendance",
    ],
    isRecommended: true,
  },
  {
    type: "Gold",
    price: "10,000",
    description:
      "Enjoy analytics and predictions of your student academic profiles",
    features: [
      "All premium plan",
      "Analytics for student performance",
      "Analytics for class performance",
      "Predictions for student",
      "Post jobs",
    ],
  },
];

export default function Pricing() {
  return (
    <div className="py-14 relative wrapper pricing">
      <h3 className="text-center mb-7 text-3xl sm:text-4xl lg:text-5xl text-black font-semibold">
        Our Pricing
      </h3>
      <p className="text-center text-base sm:text-lg wrapper md:w-[60%] mx-auto mb-16 lg:mb-24">
        The best plan for the future. We provide several package deals for you
        with attractive prices. Choose the most suitable type for you and start
        right away.
      </p>

      <div className="mt-20 gap-10 lg:gap-5 xl:gap-14 grid sm:grid-cols-2 lg:grid-cols-3">
        {plans?.map((plan, i) => (
          <PriceCard
            key={i}
            type={plan?.type}
            price={plan?.price}
            description={plan?.description}
            features={plan?.features}
            isRecommended={plan?.isRecommended}
          />
        ))}
      </div>
    </div>
  );
}
