import React from "react";
import user from "../../assets/images/product-users.png";

export default function TestimonyCard() {
  return (
    <div className="py-9 px-5 sm:px-10 rounded-lg bg-white z-10 shadow-lg">
      <p className="text-base sm:text-lg">
        She wasn’t holding herself accountable doing online work and needed to
        be in an in-person environment. Her post-graduation plans are to get her
        real estate license and build her empire.
      </p>

      <div className="flex items-center gap-5 mt-6">
        <div className="h-[60px] w-[60px] rounded-full overflow-hidden">
          <img src={user} alt="" className="w-full h-full object-cover" />
        </div>

        <div className="">
          <p className="font-bold text-lg">Hellen Cruyff</p>
          <p className="font-semibold text-purple text-lg">Parent</p>
        </div>
      </div>
    </div>
  );
}
