import { NavLink } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import Logo from "./Logo";

export default function Navbar({ toggleMenu }) {
  return (
    <nav className="flex items-center justify-between wrapper h-20 absolute top-0 left-0 right-0 bg-white">
      <div>
        <Logo />
      </div>

      <div className="hidden sm:block">
        <div className="flex items-center justify-between gap-5">
          <button>
            <NavLink to={"/"} className="text-sm font-medium">
              Home
            </NavLink>
          </button>

          <button>
            <NavLink to={"/about"} className="text-sm font-medium">
              About
            </NavLink>
          </button>

          {/* <button>
            <NavLink to={"/pricing"} className="text-sm font-medium">
              Pricing
            </NavLink>
          </button> */}

          {/* <button>
            <NavLink to={"/job"} className="text-sm font-medium">
              Jobs
            </NavLink>
          </button> */}

          <button>
            <NavLink to={"/contact"} className="text-sm font-medium">
              Contact Us
            </NavLink>
          </button>
        </div>
      </div>

      <div className="hidden sm:block">
        {/* <button className="border text-xs sm:text-sm font-medium py-1 px-4 sm:px-5 rounded-[4px] mr-2 sm:mr-3 border-purple text-purple lg:py-2">
          Log in
        </button>

        <button className="border text-xs sm:text-sm font-medium py-1 px-4 sm:px-5 rounded-[4px] bg-purple text-white border-purple lg:py-2">
          Sign up
        </button> */}
      </div>

      <div className="flex items-center gap-5 sm:hidden" onClick={toggleMenu}>
        <div className="space-y-1 cursor-pointer">
          <AiOutlineMenu className="" size={22} />
        </div>
      </div>
    </nav>
  );
}
