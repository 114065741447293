import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logoblue.png";

export default function Logo() {
  const navigate = useNavigate();

  return (
    <div
      className="text-white cursor-pointer h-6 md:h-8 xl:h-10 my-5"
      onClick={() => navigate("/")}
    >
      <img
        src={logo}
        alt="Get My Figures"
        className="block h-full object-cover"
      />
    </div>
  );
}
