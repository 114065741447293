import React from "react";
import { BsFillCircleFill } from "react-icons/bs";

export default function CareerRoleCard({
  title,
  type,
  time,
  salary,
  description,
}) {
  return (
    <div className="py-10 border-b px-5">
      <h1 className="text-lg dark-text-2 font-semibold">{title}</h1>

      <div className="flex items-center gap-3 mt-2 mb-5">
        <h1 className="text-sm text-grey">{type}</h1>
        <BsFillCircleFill className="text-[5px] text-grey" />
        <h1 className="text-sm text-grey">{time}</h1>
        <BsFillCircleFill className="text-[5px] text-grey" />
        <h1 className="text-sm text-grey">{salary}</h1>
      </div>

      <p className="text-grey">{description}</p>

      <div className="flex justify-end mt-5">
        <button className="h-12 bg-purple text-white font-semibold w-52 rounded-md">
          Apply
        </button>
      </div>
    </div>
  );
}
