import React from "react";

export default function FormTextArea({
  name,
  label,
  type = "text",
  rightIcon,
  leftIcon,
  className = "mb-5",
  bgColor = "bg-white",
  onClickRightIcon,
  labelStyle,
  ...rest
}) {
  return (
    <div className={className}>
      {label ? (
        <label className={`text-xs ${labelStyle}`}>{label}</label>
      ) : null}

      <div
        className={`rounded-md overflow-hidden border border-gray-light flex items-center px-1 ${
          label && "mt-1"
        } ${bgColor}`}
      >
        <textarea
          className="text-xs py-2 px-1 border-0 outline-0 w-full bg-transparent"
          type={type}
          // cols={5}
          rows={10}
          {...rest}
        />
      </div>
    </div>
  );
}
