import { PiExamLight, PiUsersFour } from "react-icons/pi";
import { BsGraphUpArrow } from "react-icons/bs";
import { IoSchoolOutline } from "react-icons/io5";
import { AiOutlineDashboard, AiOutlineDatabase } from "react-icons/ai";

import bg from "../../../assets/images/whyus-background.png";
import bg2 from "../../../assets/images/whyus-bg-2.png";

const data = [
  {
    icon: AiOutlineDashboard,
    title: "Streamlined Administration",
    description:
      "School management systems streamline administrative tasks such as student enrollment, attendance tracking, timetable scheduling, and grading. This automation reduces manual work, minimizes errors, and frees up administrative staff for more strategic tasks.",
    className: "sm:border-l-0  sm:rounded-l-none sm:border-b",
  },
  {
    icon: PiExamLight,
    title: "Simplified Examination and Grading",
    description:
      "The system can assist in creating and scheduling exams, and processing results. Grading can be automated, reducing manual effort and ensuring accurate evaluation.",
    className:
      "sm:border-r-0 sm:rounded-r-none sm:border-b min-[968px]:border-r min-[968px]:rounded-br-md",
  },
  {
    icon: AiOutlineDatabase,
    title: "Centralized Data",
    description:
      "All school-related information, including student records, staff details, academic data, and financial records, can be stored in a single, secure digital platform. This centralization improves data accuracy and accessibility.",
    className:
      "sm:border-l-0 sm:border-t sm:rounded-tr-md  sm:rounded-l-none min-[968px]:border-t-0 min-[968px]:rounded-bl-md min-[968px]:border-l min-[968px]:border-r-0 min-[968px]:rounded-br-none",
  },
  {
    icon: PiUsersFour,
    title: "Accurate Attendance Tracking",
    description:
      "Automated attendance tracking reduces the time spent on manual attendance processes. It also helps in identifying patterns of absenteeism and improving student accountability.",
    className:
      "sm:border-r-0 sm:border-t sm:rounded-tl-md sm:rounded-r-none min-[968px]:border-l-0 min-[968px]:rounded-tl-none min-[968px]:border-r min-[968px]:rounded-tr-md min-[968px]:border-b-0",
  },
  {
    icon: IoSchoolOutline,
    title: "Academic Performance Tracking",
    description:
      "Teachers can record and monitor student's academic progress systematically. This data assists in identifying struggling students, providing timely interventions, and creating targeted improvement plans for all students.",
    className:
      "sm:border-l-0 sm:rounded-tr-md  sm:rounded-l-none sm:border-b-0 sm:rounded-br-none sm:border-t min-[968px]:rounded-tl-md min-[968px]:border-l",
  },
  {
    icon: BsGraphUpArrow,
    title: "Customization and Scalability",
    description:
      "School management systems can often be customized to match the specific needs of an institution. They can also scale as the school grows, accommodating more students, classes, and functionalities.",
    className:
      "sm:border-r-0 sm:rounded-tl-md  sm:rounded-r-none sm:border-b-0 sm:rounded-bl-none sm:border-t",
  },
];

export default function WhyUs() {
  return (
    <div className="py-14 relative">
      <img
        src={bg}
        alt=""
        className="absolute -top-64 -left-56 md:-left-28 md:-top-52 z-0"
      />

      <img
        src={bg2}
        alt=""
        className="absolute -top-72 -left-56 md:-left-28 md:-top-60"
      />

      <h3 className="text-center mb-7 text-3xl sm:text-4xl lg:text-5xl text-black font-semibold">
        Why Use Get My Figures School Management?
      </h3>
      <p className="text-center text-lg wrapper md:w-[60%] mx-auto mb-16 lg:mb-24">
        We at Get My Figures app are passionate about transforming the education
        sector into a better experience for schools, teachers, parents and
        students.
      </p>

      <div className="grid sm:grid-cols-2 min-[968px]:grid-cols-3 wrapper">
        {data?.map((item, i) => (
          <div
            key={i}
            className={`gap-6 p-7 border-b border-l border-r rounded-b-md ${item.className}`}
          >
            <div className="flex items-center justify-center mb-8">
              <item.icon className="mt-1 text-3xl md:text-4xl text-black" />
            </div>

            <div className="">
              <h3 className="text-lg 2xl:text-2xl mb-2 font-semibold text-black text-center">
                {item.title}
              </h3>

              <p className="text-sm 2xl:text-base text-gray-500 text-center">
                {item?.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
