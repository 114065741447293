import React, { useEffect } from "react";
import careerImage from "../../assets/images/Career.png";
import { IoRocketOutline } from "react-icons/io5";
import { BsPiggyBank } from "react-icons/bs";
import { AiOutlineDashboard } from "react-icons/ai";
import CareerRoleCard from "./renderer/CareerRoleCard";

const data = [
  {
    icon: IoRocketOutline,
    title: "Track Class Statistics",
    description:
      "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante.",
    className: "sm:border-l-0  sm:rounded-l-none sm:border-b",
  },
  {
    icon: BsPiggyBank,
    title: "Track records",
    description:
      "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante.",
    className:
      "sm:border-r-0 sm:rounded-r-none sm:border-b min-[968px]:border-r min-[968px]:rounded-br-md",
  },
  {
    icon: AiOutlineDashboard,
    title: "Generate Report Card",
    description:
      "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante.",
    className:
      "sm:border-b-0 sm:rounded-br-none sm:border-l-0 sm:border-t sm:rounded-tr-md  sm:rounded-l-none min-[968px]:border-t-0 min-[968px]:rounded-bl-md min-[968px]:border-l min-[968px]:border-r-0 min-[968px]:rounded-br-none min-[968px]:border-b",
  },
];

export default function Career() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-20 pt-10 mb-5">
      <div className="mb-16">
        <h3 className="dark-text font-bold text-xl text-center mb-12">
          Join us in reforming the education sector
        </h3>

        <p className="text-center text-base wrapper max-w-3xl mx-auto">
          In a world where education plays a pivotal role in shaping the future,
          we believe in providing a seamless and innovative experience for
          schools, teachers, parents, and{" "}
        </p>

        <div className="mt-10 lg:wrapper">
          <img
            alt=""
            src={careerImage}
            className="w-full object-contain max-h-[400px]"
          />
        </div>
      </div>

      <div className="overview wrapper py-20">
        <h3 className="text-white font-bold text-xl text-center mb-12">
          Why work at Get My Figures
        </h3>

        <div className="grid sm:grid-cols-2 min-[968px]:grid-cols-3 wrapper">
          {data?.map((item, i) => (
            <div
              key={i}
              className={`gap-6 p-7 border-b border-l border-r rounded-b-md ${item.className}`}
            >
              <div className="flex items-center justify-center mb-8">
                <item.icon className="mt-1 text-3xl md:text-4xl text-white" />
              </div>

              <div className="">
                <h3 className="text-lg 2xl:text-2xl mb-2 font-semibold text-white text-center">
                  {item.title}
                </h3>

                <p className="text-sm 2xl:text-base text-grey-2 text-center">
                  {item?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="wrapper py-20">
        <div className="pb-16 border-b">
          <h3 className="dark-text font-bold text-xl text-center mb-12">
            We are hiring
          </h3>

          <p className="text-center text-base wrapper max-w-3xl mx-auto">
            In a world where education plays a pivotal role in shaping the
            future, we believe in providing a seamless and innovative experience
            for schools, teachers, parents, and{" "}
          </p>
        </div>

        <div className="lg:grid lg:grid-cols-2 lg:gap-10">
          <CareerRoleCard
            title={"Fuu Stack Developer"}
            type={"Fulltime"}
            time={"Remote"}
            description={
              "In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative experience for schools, teachers, parents, and In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative experience for schools, teachers, parents, and"
            }
            salary={"$900k - $999k"}
          />

          <CareerRoleCard
            title={"Product Designer"}
            type={"Fulltime"}
            time={"Remote"}
            description={
              "In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative experience for schools, teachers, parents, and In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative experience for schools, teachers, parents, and"
            }
            salary={"$400k - $500k"}
          />

          <CareerRoleCard
            title={"Mobile Developer"}
            type={"Fulltime"}
            time={"Remote"}
            description={
              "In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative experience for schools, teachers, parents, and In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative experience for schools, teachers, parents, and"
            }
            salary={"$500k - $700k"}
          />

          <CareerRoleCard
            title={"Backend Developer"}
            type={"Fulltime"}
            time={"Remote"}
            description={
              "In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative experience for schools, teachers, parents, and In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative experience for schools, teachers, parents, and"
            }
            salary={"$1m - $1.5m"}
          />
        </div>
      </div>
    </div>
  );
}
