import React from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import productUsersImage from "../../../assets/images/product-users.png";

export default function ProductUsers() {
  return (
    <div className="mt-10 dark-wrapper min-[900px]:grid min-[900px]:grid-cols-2 py-20">
      <div className="mb-14 min-[900px]:mb-0 xl:pt-16 flex items-center">
        <div className="min-[900px]:pr-[15%]">
          <h1 className="font-semibold text-white text-2xl mb-10 min-[960px]:mb-16 min-[960px]:text-5xl lg:text-6xl xl:text-5xl">
            Are You A School Owner? a Teacher? Or Parent?
          </h1>

          <h5 className="text-lg text-white mb-7 min-[960px]:mb-16 xl:mb-12">
            School admins, teachers and parents use Get My Figures school
            management software in tracking students progress, in hiring
            teachers, recording scorecards and monitoring class statistics
          </h5>

          <button className="flex items-center justify-center rounded-[4px] bg-purple py-2 w-48 min-[960px]:w-52 min-[960px]:py-3 xl:py-5">
            <p className="text-white text-base xl:text-lg font-medium mr-2">
              Sign Up Here
            </p>
            <FaLongArrowAltRight className="text-white" />
          </button>
        </div>
      </div>

      <div className="mb-14 min-[900px]:mb-0 flex items-center">
        <div className="w-full h-full max-h-[500px]">
          <img
            src={productUsersImage}
            alt=""
            className="h-full w-full object-contain block"
          />
        </div>
      </div>
    </div>
  );
}
