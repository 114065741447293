import React, { useEffect, useState } from "react";
import PriceCard from "../../shared/components/PriceCard";
import { plans } from "../Home/renderer/Pricing";
import PlanRow from "./renderers/PlanRow";

export default function Pricing() {
  const [YearlyPlan, setYearlyPlan] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-20 pt-10 mb-5">
      <div className="mb-16">
        <h3 className="dark-text font-bold text-xl text-center mb-12">
          Plans that are perfect for you
        </h3>

        <p className="text-center text-base wrapper max-w-3xl mx-auto">
          In a world where education plays a pivotal role in shaping the future,
          we believe in providing a seamless and innovative experience for
          schools, teachers, parents, and{" "}
        </p>

        <div className="flex items-center justify-center mt-14">
          <div className="border border-purple rounded-full overflow-hidden">
            <button
              className={`py-3 w-32 rounded-full font-semibold cursor-pointer ${
                !YearlyPlan ? "bg-purple text-white" : "dark-text"
              }`}
              onClick={() => setYearlyPlan(false)}
            >
              Monthly
            </button>

            <button
              className={`py-3 w-32 rounded-full font-semibold cursor-pointer ${
                YearlyPlan ? "bg-purple text-white" : "dark-text"
              }`}
              onClick={() => setYearlyPlan(true)}
            >
              Yearly
            </button>
          </div>
        </div>
      </div>

      <div className="wrapper mb-20">
        <div className="gap-10 lg:gap-5 xl:gap-14 grid sm:grid-cols-2 lg:grid-cols-3">
          {plans?.map((plan, i) => (
            <PriceCard
              key={i}
              type={plan?.type}
              price={plan?.price}
              description={plan?.description}
              features={plan?.features}
              isRecommended={plan?.isRecommended}
            />
          ))}
        </div>
      </div>

      <div className="overview wrapper py-20">
        <h3 className="dark-text font-bold text-xl text-center mb-12 text-white">
          Compare plans and features
        </h3>

        <p className="text-center text-base max-w-lg mx-auto text-white mb-14">
          In a world where education plays a pivotal role in shaping the future,
          we believe in providing a seamless and innovative experience for
          schools, teachers, parents, and{" "}
        </p>

        <div className="max-w-5xl mx-auto">
          <div className="grid grid-cols-5 py-5 border-b">
            <div className="col-span-2">
              <p className="text-white font-semibold text-sm">Features</p>
            </div>

            <div className="">
              <p className="text-white font-semibold text-sm text-center">
                Standard
              </p>
            </div>
            <div className="">
              <p className="text-white font-semibold text-sm text-center">
                Premium
              </p>
            </div>
            <div className="">
              <p className="text-white font-semibold text-sm text-center">
                Gold
              </p>
            </div>
          </div>

          <PlanRow feature={"Manage Student"} standard premium gold />
          <PlanRow feature={"Report Card"} standard premium gold />
          <PlanRow feature={"Add Teachers"} premium gold />
          <PlanRow feature={"Teacher's Workspace"} premium gold />
          <PlanRow feature={"Student Statistic"} gold />
          <PlanRow feature={"Class Statistic"} gold />
          <PlanRow feature={"Download Report Card"} gold />
          <PlanRow feature={"Academic Forecast"} gold />
        </div>
      </div>
    </div>
  );
}
