import React from "react";
import { IoLocationOutline } from "react-icons/io5";

import img from "../../../assets/images/lagos.png";

export default function JobRoleCard() {
  return (
    <div className=" bg-white p-6 rounded-sm">
      <div className="flex items-start gap-4 mb-10">
        <div className="h-11 w-11">
          <img alt="" src={img} className="object-cover block w-full h-full" />
        </div>

        <div>
          <h1 className="dark-text font-semibold mb-1 text-base">
            Lagos State government
          </h1>

          <div className="flex items-center gap-4">
            <h5 className="text-sm text-grey">Lekki Nigeria</h5>
            <IoLocationOutline className="text-sm text-grey" />
          </div>
        </div>
      </div>

      <h2 className="dark-text font-bold mb-5 text-lg">
        Senior Assistant teacher
      </h2>

      <p className="text-grey mb-5">
        We at Get My Figures app are passionate about transforming the education
        sector into a better experience for schools, teachers, parents and
        students.
      </p>

      <div className="flex items-center justify-between">
        <p className="dark-text font-semibold">
          ₦80,000/<span className="text-grey">month</span>
        </p>

        <button className="font-semibold text-white bg-purple h-10 w-24 rounded-md">
          Apply
        </button>
      </div>
    </div>
  );
}
