import React from "react";
import bg from "../../../assets/images/testimony-1.png";
import bg2 from "../../../assets/images/testimony-2.png";
import TestimonyCard from "../../../shared/components/TestimonyCard";

export default function Testimony() {
  return (
    <div className="py-32 wrapper overview">
      <h3 className="text-center mb-7 text-3xl sm:text-4xl lg:text-5xl text-white font-semibold">
        Review of employees through Get My Figures
      </h3>
      <p className="text-center text-base sm:text-lg text-white wrapper md:w-[60%] mx-auto mb-10">
        We at Get My Figures app are passionate about transforming the education
        sector into a better experience for schools, teachers, parents and
        students.
      </p>

      <div className="relative min-h-[650px] flex items-center justify-center">
        <img src={bg} alt="" className="absolute object-contain h-full" />
        <img src={bg2} alt="" className="absolute object-contain h-[80%]" />

        <div className="grid lg:grid-cols-2 gap-10 lg:gap-10 xl:gap-16 2xl:px-32 px-[4%]">
          <TestimonyCard />
          <TestimonyCard />
        </div>

        {/* lines */}
        <div className="absolute bottom-0 lg:bottom-[100px]">
          <div className="flex items-center justify-center gap-3">
            <div className="h-[5px] w-5 bg-testimony rounded-lg" />
            <div className="h-[5px] w-11 bg-testimony-active rounded-lg" />
            <div className="h-[5px] w-5 bg-testimony rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
}
