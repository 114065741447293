import { useEffect } from "react";
import Hero from "./renderer/Hero";
import Overview from "./renderer/Overview";
import Pricing from "./renderer/Pricing";
import ProductUsers from "./renderer/ProductUsers";
// import Testimony from "./renderer/Testimony";
import WhyUs from "./renderer/WhyUs";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white">
      <Hero />

      <Overview />

      <WhyUs />

      <ProductUsers />

      {/* <Testimony /> */}

      <Pricing />
    </div>
  );
}
