import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterSquare,
} from "react-icons/ai";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="overview wrapper">
      <div className="md:grid md:grid-cols-5 md:gap-5 lg:gap-10 py-12">
        <div className="col-span-2 mb-10 md:mb-0">
          <h2 className="text-white mb-5 text-lg">
            Get My Figures School Management
          </h2>

          <p className="text-grey text-base sm:text-lg">
            Our School Management System harnesses the power of technology to
            simplify and automate complex processes. With its intuitive
            interface and user-friendly features, it seamlessly integrates all
            aspects of school management into a single, unified platform.
          </p>
        </div>

        {/* <div className="md:flex md:justify-center inline-flex mr-10 mb-5">
          <div>
            <h3 className="text-grey mb-5 text-base sm:text-lg">Product</h3>

            <div>
              <NavLink to={"/pricing"}>
                <p className="text-white mb-3 text-base">Pricing</p>
              </NavLink>

              <NavLink to={"/job"}>
                <p className="text-white mb-3 text-base">Job</p>
              </NavLink>
            </div>
          </div>
        </div> */}

        <div className="md:flex md:justify-center inline-flex mr-10 mb-5">
          <div>
            <h3 className="text-grey mb-5 text-base sm:text-lg">Company</h3>

            <div>
              <NavLink to={"/about"}>
                <p className="text-white mb-3 text-base">About Us</p>
              </NavLink>

              <NavLink to={"/career"}>
                <p className="text-white mb-3 text-base">Career</p>
              </NavLink>

              <NavLink to={"/contact"}>
                <p className="text-white mb-3 text-base">Contact</p>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="md:flex md:justify-center inline-flex mr-10 mb-5">
          <div>
            <h3 className="text-grey mb-5 text-base sm:text-lg">Legal</h3>

            <div>
              <NavLink to={"/terms-and-condition"}>
                <p className="text-white mb-3 text-base">Terms</p>
              </NavLink>
              <NavLink to={"/privacy-policy"}>
                <p className="text-white mb-3 text-base">Privacy</p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between pt-5 pb-10 border-t border-white">
        <p className="text-[10px] md:text-xs text-white">
          Copyright © 2023 Six digits solutions
        </p>

        <div className="flex items-center gap-2">
          <AiFillFacebook className="text-xl text-white" />
          <AiFillTwitterSquare className="text-xl text-white" />
          <AiFillInstagram className="text-xl text-white" />
          <AiFillLinkedin className="text-xl text-white" />
        </div>
      </div>
    </div>
  );
}
