import React, { useEffect, useState } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
    setKey(1);
  }, []);

  const [key, setKey] = useState(1);

  const handleClickScroll = (id) => {
    setKey(id);
    const element = document.getElementById(id);

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="my-20 py-10 wrapper">
      <h2 className="text-purple font-semibold text-base mb-10 min-[960px]:mb-16">
        Get My Figures School Management
      </h2>

      <div className="mt-16 md:mt-0 md:order-1">
        <h1 className="text-xl font-bold dark-text mb-5">Privacy Policy</h1>

        <div className="lg:grid lg:grid-cols-3">
          <div className="mb-20">
            {terms?.map((term, i) => (
              <h4
                className={`font-semibold mb-5 cursor-pointer text-lg ${
                  key === term?.key ? "dark-text" : "text-grey"
                }`}
                onClick={() => handleClickScroll(term.key)}
                key={i}
              >
                {term?.number}. {term?.label}
              </h4>
            ))}
          </div>

          <div className="lg:col-span-2">
            {terms?.map((term, i) => (
              <div className="mb-10" id={term?.key} key={i}>
                <h4
                  className={`font-semibold mb-5 cursor-pointer text-lg ${
                    key === term?.key ? "dark-text" : "text-grey"
                  }`}
                >
                  {term?.label}
                </h4>

                <p
                  className={`text-base ${
                    key === term?.key ? "dark-text" : "text-grey"
                  }`}
                >
                  {term?.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const terms = [
  {
    key: 1,
    number: 1,
    label: "Agreement",
    value: `In a world where education plays a pivotal role in shaping the future, we believe in providing a seamless and innovative  experience for schools, teachers, parents, and students alike. Welcome to our revolutionary School Management System, a comprehensive platform designed to transform education and propel it into a new era of excellence.`,
  },
  {
    key: 2,
    number: 2,
    label: "Services and paid subscription",
    value: `The “Services” include all products and services provided by TestGorilla that (a) have been ordered by the Customer under any applicable ordering document (either via the Website or otherwise) that specifies pricing and other commercial terms (“Order Form”); or (b) are used by you. Our Services are not intended for personal or private individual use but designed and intended for commercial use only. will provide the Services in accordance with (a) the terms of the Agreement; and (b) in accordance with the applicable laws; and (c) the Information Security Measures. We may provide the Services, or a portion thereof, to you through our Affiliates in accordance with these Terms and any applicable Order Form(s). shall remain responsible at all times for any Services or part thereof, provided by our Aﬃliates.`,
  },
  {
    key: 3,
    number: 3,
    label: "Your account",
    value: `our Content” means any audio, video, text, images, or other material you choose to display on the Website or our Services. By displaying Your Content, you grant  a non-exclusive, worldwide, irrevocable, sub licensable license to use, reproduce, adapt, publish, translate, and distribute it in any  Your Content must be your own and must not be invading any third-party's rights. reserves the right to remove any of Your Content from the Website at any time without notice. You are however responsible for Your Content. This means you assume all risks related to it, including someone else’s reliance on its accuracy or claims relating to intellectual property or other legal rights. You agree that you will indemnify, defend and hold harmless  for all claims resulting from Your Content. But we reserve the right to assume the exclusive defence and control of such disputes, and in any event, you will cooperate with us in asserting any available defences.`,
  },
  {
    key: 4,
    number: 4,
    label: "User’s right",
    value: `In using the Services, you agree to use the Services only in accordance with these Terms and any applicable Documentation, Order Form(s), and applicable laws. You will be solely responsible for all use of the Services under your account including all applications, web domains, devices, and communication channels owned or controlled by you or owned or controlled by third parties and made available by you to the Candidates which access, use, interact with, or depend on the Services (each, a “Customer Application”).`,
  },
  {
    key: 5,
    number: 5,
    label: "Account suspension",
    value: `We may terminate or suspend access to any or all parts of our Services immediately, without prior notice and without liability, for any or no reason whatsoever, including without limitation for breach of the Terms.
13.2 We also reserve the right to change, suspend, or discontinue any of the Services at any time, for any reason. We will not be liable to you for the effect that any change may have on you, including your income or ability to generate revenue through the Services.
13.3 All provisions of the Terms which by their nature should survive termination including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability, shall survive termination`,
  },
  {
    key: 6,
    number: 6,
    label: "Maintenance and downtime",
    value: `The Services may occasionally become unavailable due to (a) the performance of scheduled or unscheduled maintenance, modifications, or upgrades; (b) hardware failures, or failures of third-party providers; (c) to mitigate or prevent the effects of any threat or attack to the Services or any other network or systems on which the Services rely; or (d) as necessary for legal or regulatory reasons. We will use reasonable efforts to communicate any scheduled service outages to you in advance.
6.2. Unless specifically stipulated in an Order Form, the SLA or the Website, TestGorilla is not liable for any damages, losses (including loss of data or proﬁts), or any other consequences incurred as a result of unavailability of Services or the failure to provide notice of unavailability.
6.3. We have the right to occasionally modify the features and operations of the Services. If we do, we will use reasonable efforts to inform you. We agree such changes to the Service will not materially diminish the overall features or functionality of the Services. Your continued use of the Services following the posting or notice of the changes will constitute your acceptance of such changes. If you do not agree to such changes, you must stop using the Services immediately. We will notify you in line with Section 15 (Amendments) if applicable legislation requires TestGorilla to provide you with specific notice of any such change.`,
  },
];
