import dashboardImage from "../../../assets/images/dashboard.png";

export default function Overview() {
  return (
    <div className="mt-10 relative z-10">
      <div className="overview py-10 wrapper rounded-tr-[30px] rounded-bl-[30px] md:rounded-tr-[40px] md:rounded-bl-[40px] lg:rounded-tr-[54px] lg:rounded-bl-[54px]">
        <h3 className="text-white text-center text-5xl mb-10">Overview</h3>

        <p className="text-white text-center text-base min-[900px]:w-[60%] mx-auto mb-5">
          In a world where education plays a pivotal role in shaping the future,
          we believe in providing a seamless and innovative experience for
          schools, teachers, parents, and students alike.
        </p>

        <p className="text-white text-center text-base min-[900px]:w-[60%] mx-auto min-[900px]:mb-16">
          Welcome to our revolutionary Get My Figures School Management System,
          a comprehensive platform designed to transform education and propel it
          into a new era of excellence
        </p>

        <div className="flex items-center justify-center">
          <img
            src={dashboardImage}
            alt=""
            className="h-[500px] object-contain block"
          />
        </div>
      </div>
    </div>
  );
}
