import React, { useEffect } from "react";
import jobImage from "../../assets/images/opportinity.png";
import { FaLongArrowAltRight } from "react-icons/fa";
import Testimony from "./renderer/Testimony";
import JobRoleCard from "./renderer/JobRoleCard";

export default function Job() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-20 pt-10 pb-20">
      <h2 className="text-purple font-semibold text-base mb-10 min-[960px]:mb-16 wrapper">
        Employee
      </h2>

      <div className="md:grid md:grid-cols-2 mb-20">
        {/* image */}
        <div className="pl-[4%] md:pl-0 md:order-2">
          <div className="relative h-96">
            <div className="h-[90%] bg-purple absolute bottom-0 left-0 right-0" />

            <div className="h-[90%] w-[90%] bg-yellow absolute top-0 right-0">
              <img
                src={jobImage}
                alt=""
                className="object-contain object-right-bottom block w-full h-full"
              />
            </div>
          </div>
        </div>

        {/* text */}
        <div className="wrapper mt-16 md:mt-0 md:order-1">
          <div className="md:pr-[5%] md:wrapper">
            <h1 className="text-xl font-bold dark-text mb-5">
              Kick start your teaching career with us
            </h1>

            <p className="text-sm mb-10">
              In a world where education plays a pivotal role in shaping the
              future, we believe in providing a seamless and innovative
              experience for schools, teachers, parents, and students alike.
              Welcome to our revolutionary School Management System, a
              comprehensive platform designed to transform education and propel
              it into a new era of excellence.
            </p>

            <div>
              <button className="flex items-center justify-center rounded-[4px] bg-purple px-2 py-2 w-48 min-[960px]:w-52 min-[960px]:py-3 xl:py-4">
                <p className="text-white text-base font-medium mr-2">
                  Explore Opportunity
                </p>
                <FaLongArrowAltRight className="text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* testimony */}
      <Testimony />

      {/* Job openings */}
      <div className="py-20 mt-10 bg-purple-2 wrapper">
        <h3 className="text-center mb-7 text-3xl sm:text-4xl lg:text-5xl dark-text font-semibold">
          Discover best job openings for you
        </h3>
        <p className="text-center text-base sm:text-lg text-grey wrapper md:w-[60%] mx-auto">
          We at Get My Figures app are passionate about transforming the
          education sector into a better experience for schools, teachers,
          parents and students.
        </p>

        <div className="grid md:grid-cols-2 gap-10 mt-20">
          <JobRoleCard />
          <JobRoleCard />
          <JobRoleCard />
          <JobRoleCard />
        </div>

        {/* <div>
            <button>View All Openings</button>
        </div> */}
      </div>
    </div>
  );
}
