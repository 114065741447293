import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";

export default function PlanRow({ feature, standard, premium, gold }) {
  return (
    <div className="grid grid-cols-5 py-5 border-b">
      <div className="col-span-2">
        <p className="text-white font-semibold text-sm">{feature}</p>
      </div>

      <div className="flex justify-center">
        {standard ? (
          <AiOutlineCheckCircle className="text-purple text-lg" />
        ) : null}
      </div>

      <div className="flex justify-center">
        {premium ? (
          <AiOutlineCheckCircle className="text-purple text-lg" />
        ) : null}
      </div>

      <div className="flex justify-center">
        {gold ? <AiOutlineCheckCircle className="text-purple text-lg" /> : null}
      </div>
    </div>
  );
}
