import React, { useEffect } from "react";
import aboutImage from "../../assets/images/contact.png";
import FormInput from "../../shared/components/forms/FormInput";
import FormTextArea from "../../shared/components/forms/FormTextArea";
import { FaLongArrowAltRight } from "react-icons/fa";

export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-20 pt-10">
      <h2 className="text-purple font-semibold text-base mb-10 min-[960px]:mb-16 wrapper">
        Contact us
      </h2>

      <div className="md:grid md:grid-cols-2">
        {/* image */}
        <div className="pl-[4%] md:pl-0 md:order-2">
          <div className="relative h-96">
            <div className="h-[90%] bg-purple absolute bottom-0 left-0 right-0" />

            <div className="h-[90%] w-[90%] bg-yellow absolute top-0 right-0">
              <img
                src={aboutImage}
                alt=""
                className="object-contain object-right-bottom block w-full h-full"
              />
            </div>
          </div>
        </div>

        {/* text */}
        <div className="wrapper mt-16 md:mt-0 md:order-1">
          <div className="md:pr-[5%] md:wrapper">
            <h1 className="text-xl font-bold dark-text mb-5">
              Are you curious about anything?
            </h1>

            <p className="text-sm mb-10">
              In a world where education plays a pivotal role in shaping the
              future, we believe in providing a seamless and innovative
              experience for schools, teachers, parents, and students alike.
              Welcome to our revolutionary School Management System, a
              comprehensive platform designed to transform education and propel
              it into a new era of excellence.
            </p>

            <div>
              <h6 className="text-sm mb-3">Our Contact</h6>
              <p className="dark-text text-sm font-semibold mb-1">
                getmyfigures@gmail.com
              </p>
              <p className="dark-text text-sm font-semibold mb-1">
                09026611797, 07056045509
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* form */}
      <div className="flex justify-center wrapper bg-grey py-20 mt-14">
        <div className="w-[90%] max-w-lg">
          <FormInput
            label={"Full Name"}
            placeholder={"Enter full name"}
            labelStyle={"dark-text font-semibold"}
          />
          <FormInput
            label={"Email Address"}
            placeholder="Enter your email address"
            labelStyle={"dark-text font-semibold"}
          />
          <FormTextArea
            label={"Your Message"}
            placeholder="Type your message here"
            labelStyle={"dark-text font-semibold"}
          />

          <div className="flex items-center justify-center">
            <button className="flex items-center justify-center rounded-[4px] bg-purple w-48 min-[960px]:w-52 h-12">
              <p className="text-white text-sm font-medium mr-2">
                Send message
              </p>
              <FaLongArrowAltRight className="text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
