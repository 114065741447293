import { AiFillCheckCircle } from "react-icons/ai";

export default function PriceCard({
  type,
  price,
  description,
  features,
  isRecommended,
}) {
  return (
    <div
      className={`border px-6 xl:px-10 py-20 relative rounded-tr-[40px] rounded-bl-[40px] ${
        isRecommended ? "overview" : "bg-white"
      }`}
    >
      <div className="">
        <h4
          className={`mb-10 text-2xl text-primary-3 font-semibold ${
            isRecommended ? "text-white" : "text-black"
          }`}
        >
          {type}
        </h4>

        <p
          className={`text-4xl mb-5 text-primary font-bold ${
            isRecommended ? "text-white" : "text-black"
          }`}
        >
          ₦{price}
        </p>

        <p
          className={`flex-1 text-base ${
            isRecommended ? "text-white" : "text-black"
          }`}
        >
          {description}
        </p>
      </div>

      <button className="py-3 my-16 w-full bg-purple rounded-md text-lg font-semibold text-white">
        Get started
      </button>

      <div className="">
        <p
          className={`text-lg font-bold mb-4 ${
            isRecommended ? "text-white" : "text-black"
          }`}
        >
          {type} includes:
        </p>

        {features?.map((feature, i) => (
          <div className="flex items-center gap-3 mb-4" key={i}>
            <AiFillCheckCircle
              className={`text-lg ${
                isRecommended ? "text-white" : "text-black"
              }`}
            />
            <p
              className={`text-base font-light ${
                isRecommended ? "text-white" : "text-black"
              }`}
            >
              {feature}
            </p>
          </div>
        ))}
      </div>

      {isRecommended ? (
        <button className="absolute top-7 right-10 bg-white px-5 py-1 rounded-sm text-purple font-semibold">
          Popular
        </button>
      ) : null}
    </div>
  );
}
