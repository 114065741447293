import React from "react";

export default function FormInput({
  name,
  label,
  type = "text",
  rightIcon,
  leftIcon,
  className = "mb-5",
  bgColor = "bg-white",
  onClickRightIcon,
  labelStyle,
  ...rest
}) {
  return (
    <div className={className}>
      {label ? (
        <label className={`text-xs ${labelStyle}`}>{label}</label>
      ) : null}

      <div
        className={`rounded-md overflow-hidden border border-gray-light flex items-center px-1 h-9 min-[360px]:h-10 ${
          label && "mt-1"
        } ${bgColor}`}
      >
        {leftIcon ? (
          <div className="h-full flex justify-center items-center px-1">
            {leftIcon}
          </div>
        ) : null}

        <input
          className="text-xs py-0 px-1 border-0 outline-0 bg-transparent flex-1 h-full text-gray-text"
          type={type}
          {...rest}
        />

        {rightIcon ? (
          <div
            className="h-full flex justify-center items-center px-1"
            onClick={rightIcon ? onClickRightIcon : null}
          >
            {rightIcon}
          </div>
        ) : null}
      </div>
    </div>
  );
}
